
import { defineComponent } from "vue";
import Card from "primevue/card";
import { mapGetters } from "vuex";
import Attachments from "@/components/Attachments/Attachments.vue";

export default defineComponent({
  name: "SalesAttachments",
  components: {
    Card,
    Attachments,
  },

  data() {
    return {
      currentId: "",
      isUploading: false,
      showAddTicketAttachmentDialog: false,
      showEmailAttachmentDialog: false,
      currentAttachmentId: "",
      fileName: "",
      attachmentsList: [],
    };
  },
  computed: {
    ...mapGetters({
      getActiveOrderTab: "salesInquiry/getActiveOrderTab",
    }),
  },
  created() {
    this.getData();
  },
  updated() {
    this.getData();
  },
  methods: {
    getData() {
      if (this.$attrs.orderId) {
        this.currentId = this.$attrs.orderId as string;
        this.attachmentsList =
          this.getActiveOrderTab(this.currentId).attachments_items || [];
      }
    },
    showEmailModal({ id, file_name }: any) {
      this.showEmailAttachmentDialog = true;
      this.currentAttachmentId = id;
      this.fileName = file_name;
    },
  },
});
