import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid m-0 order-tab-content p-0" }
const _hoisted_2 = { class: "col-12 justify-content-between p-0 pb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Attachments = _resolveComponent("Attachments")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Card, { class: "col-12 border-round-3xl mb-0 pl-2 tab-card-content" }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Attachments, {
            hostFile: "SO",
            attachments: _ctx.attachmentsList,
            recordId: _ctx.currentId,
            showMail: true
          }, null, 8, ["attachments", "recordId"])
        ])
      ]),
      _: 1
    })
  ]))
}