
import { defineComponent } from "vue";
import { mapActions, mapGetters, mapState } from "vuex";
import Datatable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";

import AddAttachmentDialog from "@/components/Attachments/AddAttachmentDialog.vue";
import ConfirmChangesDialog from "@/components/ConfirmChangesDialog.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

import Attachment from "@/types/attachment";

import Utils from "@/utility/utils";

import AttachmentService from "@/services/AttachmentService";

const attachmentService = new AttachmentService(
  process.env.VUE_APP_ABSTRACTION_API,
);

export default defineComponent({
  name: "Attachments",
  components: {
    Datatable,
    Column,
    Button,
    AddAttachmentDialog,
    ConfirmChangesDialog,
    LoadingSpinner,
  },
  props: {
    hostFile: String,
    attachments: Array,
    recordId: String,
  },
  emits: ["onUploadAttachment"],
  mounted() {
    this.fetchAttachmentRecords();
  },
  updated() {
    this.fetchAttachmentRecords();
  },
  data() {
    return {
      isHidden: false,
      isAttachmentLogHidden: true,
      showAddAttachmentDialog: false,
      loadingAttachments: false,
      currentAttachmentId: "",
      fileName: "",
      attachmentRecords: [] as Array<Attachment>,
      loadingAttachmentIds: [] as Array<string>,
      deletingAttachmentIds: [] as Array<string>,
      isUploading: false,
      rows: 5,
      first: 0,
      page: 1,
      currentId: -1,
    };
  },
  computed: {
    ...mapState(["control"]),
    ...mapGetters({
      getAttachments: "attachments/getCustomerAttachments",
      getRangeEnd: "attachments/getCustomerAttachmentRangeEnd",
      getFolderNames: "control/getFolderNames",
      getClient: "session/getClient",
      getUser: "session/getUser",
      getFirstRow: "attachments/getCustomerAttachmentFirstRow",
    }),
    totalAttachments(): number {
      return this.attachmentRecords.length || 0;
    },
    isAttachmentLogVisible(): boolean {
      return this.isAttachmentLogHidden;
    },
  },
  methods: {
    ...mapActions({
      fetchControl: "control/fetchControl",
      postAttachment: "attachments/postAttachment",
      downloadAttachment: "attachments/downloadAttachment",
      deleteAttachment: "attachments/deleteAttachment",
      fetchAttachments: "attachments/fetchAttachments",
      addNotification: "notification/add",
      updatePage: "attachments/updatePage",
      setNextRow: "attachments/updateCustomerRowIndex",
    }),
    viewAttachmentsLog() {
      this.isAttachmentLogHidden = !this.isAttachmentLogHidden;
    },
    getDate(attachment: Attachment) {
      if (attachment && attachment.date_created) {
        return Utils.formatDate(attachment.date_created);
      } else {
        return "";
      }
    },
    getDescription(id: string) {
      const attachment: any = this.attachments?.find(
        (attachment: any) => attachment.attachments === id,
      );

      if (attachment) {
        return attachment.attachment_desc;
      } else {
        return "";
      }
    },
    downloadIcon(ticketId: string) {
      const downloading = this.loadingAttachmentIds.includes(ticketId) || false;

      if (!downloading) return "pi pi-download";
      return "pi pi-spin pi-spinner";
    },
    download({ id }: any) {
      this.loadingAttachmentIds.push(id);

      const attachment: Attachment = this.attachmentRecords?.find(
        (attachment) => attachment.id === id,
      ) as Attachment;

      if (attachment == null) {
        this.addNotification({
          message: `Error downloading attachment`,
          type: "error",
        });
        return;
      }

      const mime_type = attachment.mime_type || "text/plain";

      this.downloadAttachment({
        id,
        mime_type,
      })
        .catch((error) => {
          this.addNotification({
            message: `Error downloading attachment: "${attachment.file_name}"`,
            type: "error",
          });
        })
        .finally(() => {
          this.removeDownloadingState(id);
        });
    },
    remove(id: string) {
      this.deletingAttachmentIds.push(id);
    },
    removeDeleteLoadingState(id: string) {
      const attachmentId = this.deletingAttachmentIds.indexOf(id);
      if (attachmentId > -1) {
        this.deletingAttachmentIds.splice(attachmentId, 1);
      }
    },
    removeDownloadingState(id: string) {
      const attachmentId = this.loadingAttachmentIds.indexOf(id);
      if (attachmentId > -1) {
        this.loadingAttachmentIds.splice(attachmentId, 1);
      }
    },
    save(data: any) {
      const { desc, doc, doc_name, folder, mime_type } = data;

      this.isUploading = true;

      this.postAttachment({
        doc_name,
        doc,
        mime_type,
        folder,
        host_file: this.hostFile,
        host_id: this.recordId,
        user_id: this.getUser.user_id,
        desc,
        index: 0,
      })
        .then((resp) => {
          this.$emit("onUploadAttachment", { success: true, data: resp });
          this.addNotification({
            message: `Attachment saved successfully`,
            type: "success",
          });
          this.showAddAttachmentDialog = false;
          this.first = 0;
        })
        .catch((error) => {
          let err = "";

          if (error.response) {
            err = error.response.data.message;
          } else {
            err = error.error;
          }
          this.addNotification({
            message: `Failed to save attachment: ${err}`,
            type: "error",
          });
        })
        .finally(() => {
          this.isUploading = false;
        });
    },
    fetchAttachmentRecords(isAddingAttachments?: boolean) {
      if (this.attachmentRecords.length > 0) {
        return;
      }

      const idArray =
        this.attachments?.map((attachment: any) => attachment.attachments) ||
        [];
      const ids = idArray.join(" ");
      if (ids) {
        attachmentService
          .getAttachments(ids, 1, idArray.length)
          .then((resp: any) => {
            this.attachmentRecords = resp.attachment_items
              ? (resp.attachment_items as Array<Attachment>)
              : [];
          });
      }
    },
  },
});
